.WelcomeTile {
    background-color: white;
    @media (min-width: 550px) and (max-width: 800px) {
        background-color: var(--background);
    }
    @media (min-width: 801px) {
        padding: 36px;
    }
    @media (min-width: 1024px) {
        padding: 48px;
    }
    @media (min-width: 1200px) {
        padding: 60px;
    }

    & > .title {
        margin-bottom: 12px;
    }

    & > p {
        margin-top: 12px;
    }

    & > h4 {
        margin-top: 24px;
    }

    & > .button-group {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        margin-top: 24px;

        @media (min-width: 1000px) {
            margin-top: 36px;
            flex-direction: row;
        }

        & button {
            width: 100%;
        }

        & a:first-of-type {
            @media (max-width: 1000px) {
                margin-bottom: 12px;
            }
            @media (min-width: 1000px) {
                margin-right: 16px;
            }
        }
    }
}