.Toolbar {
    @media (min-width: 800px) {
        background-color: var(--background);
    }

    & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding-top: 36px;
        padding-bottom: 36px;
        @media (min-width: 800px) {
            padding-top: 72px;
            padding-bottom: 48px;
        }

        #toolbar-logo {
            @media (max-width: 800px) { 
                width: 96px;
            }
        }
        
        & > svg {
            margin-right: 24px;
            @media (min-width: 800px) {
                margin-right: 32px;
            }
        }

        
        & > img {
            mix-blend-mode: darken;
            height: 48px;
            max-width: 200px;
            object-fit: contain;
            @media (max-width: 400px) {
                width: 0;
                flex-grow: 1;
            }
        }
    }
}