.contact-section {
    grid-column: 1 / span 2;
    margin: 0;
    @media (max-width: 799px) {
        margin-bottom: -60px;
    }

    & > .contact-tile {
        @media (min-width: 550px) {
            padding: 36px;
        }
        @media (min-width: 650px) {
            padding: 48px;
        }
        @media (min-width: 800px) {
            padding: 60px;
        }
        width: 100%;

        & > form {
            display: flex;
            flex-direction: column;

            margin-top: 6px;
            margin-bottom: 24px;
            @media (min-width: 550px) {
                margin-top: 18px;
                margin-bottom: 24px;
            }

            & > label {
                margin-top: 18px;
                margin-bottom: 6px;
            }
        }

        & > .horizontal {
            display: flex;

            flex-direction: column;
            @media (min-width: 550px) {
                flex-direction: row;
                align-items: flex-start;
            }
            
            & > .feedback {
                display: flex;
                flex-direction: row;
                
                margin-top: 24px;
                align-self: flex-start;
                @media (min-width: 550px) {
                    margin-top: 0;
                    margin-left: 36px;
                    align-self: center;
                }
                
                &.success {
                    color: var(--success);
                }

                &.error {
                    color: var(--error);
                }
                
                & > .feedback-icon {
                    margin-right: 8px;
                }
            }
        }
    }
}