#hero-right-container {
  @media (max-width: 549px) {
    grid-row: 1;
    & > #hero-image-tile {
      height: calc(((100vw - (2 * 36px)) * 1.02) - 30px);
      width: 100%;
    }
  }
  @media (min-width: 550px) and (max-width: 1000px) {
    & > #hero-image-tile {
      height: 100%;
      width: 100%;
    }
  }
  &.sticky {
    height: fit-content;
    & > #hero-image-tile {
      height: 400px;
    }

    &.full {
      position: sticky;

      top: 108px;
      @media (min-width: 800px) {
        top: 168px;
      }
    }
    &.buttons {
      height: 100%;

      & > #hero-right-button-list {
        position: sticky;
        top: 0;
      }
    }
  }

  & > #see-more-fab {
    padding: 0;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 24px;
    right: 24px;
    z-index: 4;
    @media (min-width: 550px) {
      display: none;
    }
  }

  & > #hero-right-button-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media (max-width: 550px) {
      width: calc(100vw - (2 * 36px));
      position: fixed;
      bottom: 104px;
      right: 24px;
      z-index: 3;

      transition: bottom 0.2s ease, opacity 0.25s ease, height 0.2s ease;

      & > a {
        background-color: white;
      }

      &.closed {
        opacity: 0;
        bottom: 0;
        height: 0;
      }
      &.opened {
        bottom: 104px;
        opacity: 1;
        height: 177px;
      }
    }

    & > a {
      margin-top: 12px;
      border-radius: 4px;

      & > button {
        width: 100%;
      }
    }
  }
}

#education .storytile-container {
  @media (min-width: 1100px) {
    grid-column: 1 / span 3;
  }
}

#unl-courses-highlight {
  @media (min-width: 800px) {
    grid-column: 2;
    grid-row: 2 / span 2;
  }
}

#involvements-info-bit {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

#raikes-info-bit {
  margin-top: 36px;

  & > .title {
    margin-bottom: 12px;
  }
}

.logo-tile-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: 550px) and (max-width: 999px) {
    grid-column: 1 / span 2;
  }

  & > .logo-grid {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    @media (min-width: 1000px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    }
    grid-gap: 24px;

    & .logo-tile {
      width: 100%;
      height: 100%;
      @media (max-width: 999px) {
        height: calc((100vw - (2 * 36px) - 24px) / 2);
        max-height: 350px;
      }
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 550px) {
        background-color: var(--gray);

        & > img {
          width: 50%;
        }
      }
      transition: background-color 0.25s ease;

      &:hover {
        cursor: pointer;
        background-color: #f2f2f2;
      }
    }
  }
}

.overview-video {
  width: 100%;
  aspect-ratio: 16 / 9;
}
