* {
  box-sizing: border-box;

  --success: rgba(39, 147, 115, 1);
  --error: rgba(211, 19, 42, 1);
  --error-opacity: rgba(211, 19, 42, 0.1);
  --background: #f7f7f7;
  --gray: #f2f2f2;
}

body {
  width: 100vw;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
a,
label,
input,
textarea,
button {
  font-family: "Manrope", sans-serif;
  margin: 0;
}

h1 {
  font-family: "Manrope", sans-serif;
  font-weight: 700;

  font-size: 22px;
  line-height: 32px;
  @media (min-width: 900px) {
    font-size: 28px;
    line-height: 40px;
  }
  @media (min-width: 1000px) {
    font-size: 32px;
    line-height: 42px;
  }
  @media (min-width: 1200px) {
    font-size: 36px;
    line-height: 48px;
  }
}

h2 {
  font-size: 24px;
  line-height: 36px;
  @media (min-width: 800px) {
    font-size: 32px;
    line-height: 48px;
  }
  font-weight: 600;
}

h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 48px;
  text-transform: uppercase;
  letter-spacing: 4px;
}

h4 {
  font-size: 20px;
  @media (min-width: 800px) {
    font-size: 24px;
  }
  font-weight: 600;
}

h5 {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: #797979;
}

p.large {
  font-weight: 500;

  @media (min-width: 1024px) {
    font-size: 18px;
    line-height: 28px;
  }
  @media (min-width: 1200px) {
    font-size: 20px;
    line-height: 30px;
  }
}
p,
label,
input,
textarea {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

a {
  font-weight: 700;
}

input,
textarea {
  padding: 12px 18px;
  background-color: var(--gray);
  border: 2px solid var(--gray);
  border-radius: 4px;
  outline: none;
  transition: background-color 0.25s ease, border 0.25s ease;
}
input.error,
textarea.error {
  border: 2px solid var(--error);
  background-color: var(--error-opacity);
}

textarea {
  resize: none;
}

button {
  padding: 14px 28px;
  border: 0;
  border-radius: 4px;
  outline: none;
  font-weight: 800;
  font-size: 14px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  transition: background-color 0.25s ease;
}
button:hover {
  cursor: pointer;
}

ul {
  padding-left: 20px;
}
