#hero-image-tile {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    @media (min-width: 800px) {
        width: 100%;
        margin-right: 0;
    }

        & > svg {
            @media (min-width: 800px) {
                width: 100%;
            }
            position: absolute;
            left: 0;
            z-index: 1;
            mix-blend-mode: hue;
            @supports not (mix-blend-mode: hue) {
                display: none;
            }
            _::-webkit-full-page-media, _:future, :root & { 
                display: none;
            }
            
            height: calc(100% + 30px);
            top: -30px;
            @media (min-width: 550px) and (max-width: 700px) {
                top: calc(70px + ((100vw - 550px) * -100 / 150));
                height: calc(100% + (-70px + ((100vw - 550px) * 100 / 150)));
            }
            @media (max-width: 799px) {
                width: 100%;
            }
            @media (min-width: 900px) {
                height: calc(100% + 60px);
                top: -60px;
            }
            @media (min-width: 1025px) {
                height: calc(100% + 80px);
                top: -80px;
            }
        }

        & > img {
            @media (min-width: 800px) {
                width: 100%;
            }
            position: absolute;
            object-fit: cover;
            left: 0;
            border-radius: 0 0 4px 4px;
            overflow: hidden;

            height: calc(100% + 30px);
            top: -30px;
            @media (min-width: 550px) and (max-width: 700px) {
                top: calc(70px + ((100vw - 550px) * -100 / 150));
                height: calc(100% + (-70px + ((100vw - 550px) * 100 / 150)));
            }
            @media (max-width: 799px) {
                width: 100%;
            }
            @media (min-width: 900px) {
                height: calc(100% + 60px);
                top: -60px;
            }
            @media (min-width: 1025px) {
                height: calc(100% + 80px);
                top: -80px;
            }
        }
}