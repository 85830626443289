.IconButton {
    box-sizing: border-box;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    & > i {
        height: 24px;
        width: 24px;
    }
}