.storytile-container{
    position: relative;
    height: calc(100vw - (2 * 36px));
    @media (min-width: 425px) {
        height: calc(300px + ((100vw - 425px) * 100 / 175));
    }
    @media (min-width: 600px) {
        height: 400px;
    }
    @media (min-width: 1000px) {
        height: calc(400px + ((100vw - 1000px) * 100 / 200));
    }
    @media (min-width: 1200px) {
        height: 500px;
    }

    & > .storytile {
        will-change: width, height, opacity;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;
        @media (min-width: 800px) {
            flex-direction: row;
        }

        & > .preview {
            will-change: width, height, opacity;
            position: relative;
            height: calc(100vw - (2 * 36px));
            @media (min-width: 425px) {
                height: calc(300px + ((100vw - 425px) * 100 / 175));
            }
            @media (min-width: 600px) {
                height: 400px;
            }
            @media (min-width: 1000px) {
                height: calc(400px + ((100vw - 1000px) * 100 / 200));
            }
            @media (min-width: 1200px) {
                height: 500px;
            }
            width: 100%;

            & > .title {
                position: absolute;
                bottom: 0;
                left: 0;
                margin: 24px;
                color: white;
                z-index: 2;
            }
            
            & > .logoImage {
                position: absolute;
                top: 24px;
                right: 24px;
                z-index: 2;
                mix-blend-mode: lighten;
                width: auto;
                object-fit: contain;

                height: 24px;
                max-width: 75px;
                @media (min-width: 550px) {
                    height: 36px;
                    max-width: 90px;
                }
            }
            
            & > .scrim {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.5));
                z-index: 1;
            }

            & > .mainImage {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        & > .details {
            will-change: width, height, opacity;
            height: 0;
            width: 0;
            opacity: 0;
            overflow-y: auto;
    
            & .title {
                margin-bottom: 36px;
            }
    
            & .highlight-grid {
                display: grid;
                grid-template-columns: 1fr;
                @media (min-width: 800px) {
                    grid-template-columns: 1fr 1fr;
                }
                grid-column-gap: 36px;
                grid-row-gap: 24px;
    
                & .section-heading {
                    margin-bottom: 6px;
                }
    
                & .full-width {
                    @media (min-width: 800px) {   
                        grid-column: 1 / span 2;
                    }
                    
                    & > p {
                        margin-top: 12px;
                    }
                    & > p:first-of-type {
                        margin-top: 0;
                    }

                    & button {
                        margin-top: 8px;
                    }
                }
    
                & .skills-highlight-grid {
                    display: grid;
                    grid-template-columns: 1fr;
                    
                    @media (min-width: 800px) {
                        grid-template-columns: 1fr 1fr 1fr;
                    }
                }
                    
                & .icon-bullet-container {
                    margin-bottom: 6px;
                }
                
            }

        }
    }

    & > .full-scrim {
        position: fixed;
        cursor: pointer;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        z-index: -1;
        background-color: rgba(0, 0, 0, 0);
    }

    & > .close-button {
        position: fixed;

        top: 18px;
        right: 18px;
        @media (min-width: 800px) {
            top: 36px;
            right: 36px;
        }
        color: white;
        background-color: rgba(255,255,255, 0);
        z-index: -1;
        transition: background-color 0.25s ease;
        
        &:hover {
            background-color: rgba(255,255,255, 0.2);
        }

        &.dark {
            color: black;
            background-color: rgba(0,0,0, 0);
            top: 12px;
            right: -6px;

            &:hover {
                background-color: rgba(0, 0, 0, 0.2);
            }
        }
        
    }
}

.storytile-container.display {
    height: 100%;
    & > .storytile {
        align-items: stretch;

        & > .preview {
            height: inherit;
            width: 30%;
            @media (min-width: 1250px) {
                width: 40%;
            }

            & > .title,
            & > .logoImage,
            & > .scrim {
                opacity: 0;
            }
        }

        & > .details {
            width: 70%;
            @media (min-width: 1250px) {
                width: 60%;
            }
            padding: 60px;
            opacity: 1;
            height: 100%;
        }
    }
}

.storytile-container.active > .storytile {
    height: calc(100vw - (2 * 36px));
    @media (min-width: 425px) {
        height: calc(300px + ((100vw - 425px) * 100 / 175));
    }
    @media (min-width: 600px) {
        height: 400px;
    }
    @media (min-width: 1000px) {
        height: calc(400px + ((100vw - 1000px) * 100 / 200));
    }
    @media (min-width: 1200px) {
        height: 500px;
    }

    &:hover {
        cursor: pointer;
    }

    & > .details {
        opacity: 0;
    }
}
